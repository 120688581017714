<template>
  <!-- 付款银行设置  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <el-button type="success" size="mini" @click="dialogVisible = true"
        >增加银行账户</el-button
      >
    </div>
    <el-divider></el-divider>
    <div class="d-flex align-items-center mt-5">
      <el-button size="mini" @click="loadtabledata('1')">收付款账户</el-button>
      <el-button size="mini" @click="loadtabledata('2')">往来款账户</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="account_name" label="户名" width="280" />
      <el-table-column prop="bank_no" label="账号" width="240" />
      <el-table-column prop="bank_name" label="银行名" width="280" />
      <el-table-column prop="banksclass" label="账户类别" width="180" />
      <el-table-column label="操作" width="300" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" plain @click="edituser(scope.row)"
              >修改</el-button
            >
            <el-button size="small" plain type="danger">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <!-- 新增账户 -->
  <el-dialog v-model="dialogVisible" title="新增账户" width="30%">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="户名">
        <el-input v-model="formInline.account_name"></el-input>
      </el-form-item>
      <el-form-item label="账号:">
        <el-input v-model="formInline.bank_no"></el-input>
      </el-form-item>
      <el-form-item label="银行名">
        <el-input v-model="formInline.bank_name"></el-input>
      </el-form-item>
      <el-form-item label="账户类别">
        <el-select
          v-model="formInline.banksclass"
          class="m-2"
          placeholder="Select"
          size="small"
        >
          <el-option key="1" label="1-收付款账户" value="1"></el-option>
          <el-option key="2" label="2-股东款账户" value="2"></el-option>
          <el-option key="3" label="3-资金往来账户" value="3"></el-option>
        </el-select>
      </el-form-item>
            <el-form-item label="备注">
        <el-input type="textarea" v-model="formInline.banks_text"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </span>
    </template>
  </el-dialog>
 </template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      banks_id: "", //选中的用户ID
      tableData: [],
      dialogVisible: false, //控制新增银行对话框关闭
      isOpenEdit:false,

      formInline: {
        account_name: "", //户名
        bank_name: "", //银行名
        bank_no: "", //账号
        banks_text: "", //备注说明
        banksclass: "", //账户类别
        project_id: window.sessionStorage.getItem("project_ids"),
      },
    };
  },
  created() {
    // 1-表示收付款账户  2表示往来款账户
    this.loadtabledata(1);
  },
  methods: {

    //点击确定保存新增银行按钮
    onSubmit() {
      //保存新增数据到数据库中
      this.adduser(); //新增银行
    },
    //新增银行
    adduser() {
      axios
        .post("admin/SfBanks", this.formInline, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });

          this.dialogVisible = false;
          // 1-表示收付款账户  2表示往来款账户
          this.loadtabledata(this.formInline.banksclass); //重新加载用户列表
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },

    //加载银行账户
    loadtabledata(banksclass) {
      this.axios
        .get("admin/SfBanks", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            banksclass: banksclass, // 1-表示收付款账户  2表示往来款账户
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    edituser(rowid) {
      console.log(rowid);
    },

  }
};
</script>


<style>
</style>