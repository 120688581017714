<template>
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <!-- <el-tab-pane label="用户设置" name="first"><set-user></set-user></el-tab-pane> -->
      <el-tab-pane label="成本分类" name="second"
        ><set-category></set-category
      ></el-tab-pane>
      <el-tab-pane label="供应商设置" name="third"
        ><set-supplierinfo></set-supplierinfo
      ></el-tab-pane>
      <el-tab-pane label="银行账户设置" name="fourth"
        ><set-skbanks></set-skbanks
      ></el-tab-pane>
      <el-tab-pane label="部门设置" name="five"
        ><set-department></set-department
      ></el-tab-pane>
      <el-tab-pane label="发票购买方" name="six"
        ><set-invoicebuys></set-invoicebuys
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import usersetVue from "../../../components/manager/useset.vue";
import setcategoryVue from "../../../components/manager/setcategory.vue";
import setsupplierinfoVue from "../../../components/manager/setsupplierinfo.vue";
import setskbanksVue from "../../../components/manager/setskbanks.vue";
import setdepartmentVue from "../../../components/manager/setdepartment.vue";
import setinvoicebuysVue from "../../../components/manager/setinvoicebuys";

export default {
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  components: {
    //  "set-user": usersetVue,
    "set-category": setcategoryVue,
    "set-supplierinfo": setsupplierinfoVue,
    "set-skbanks": setskbanksVue,
    "set-department": setdepartmentVue,
    "set-invoicebuys": setinvoicebuysVue,
  },
};
</script>

<style></style>
