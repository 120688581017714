<template>
  <!-- 供应商信息表  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <span>成本类别</span>
      <select-cbfl
        class="ml-2"
        selectw="450px"
        @select-change="chenbenfenliselect"
      ></select-cbfl>
      <el-button
        class="ml-3"
        type="success"
        size="mini"
        :disabled="isaddsupplierbtn"
        @click="dialogVisible = true"
      >
        新增供应商</el-button
      >
      <el-button class="ml-3" type="success" size="mini" @click="displayall"
        >显示所有供应商</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="supplier_name" label="名称" width="350" />
      <el-table-column prop="category_id" label="成本分类" width="450" />
      <el-table-column label="操作" width="300" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" plain @click="edituser(scope.row)"
              >修改</el-button
            >
            <el-button
              size="small"
              plain
              type="danger"
              @click="deletesupplier(scope.row.id)"
              >删除</el-button
            >
            <el-button
              size="small"
              plain
              @click="clickaddbanksbut(scope.row.id)"
              >添加银行信息</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <!-- 新增供应商 -->
  <el-dialog v-model="dialogVisible" title="新增供应商" width="30%">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="名称：">
        <el-input v-model="formInline.supplier_name"></el-input>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model="formInline.supplier_text" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitsupplier">保存</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 添加银行信息 -->
  <el-dialog v-model="dialogVisibleup" title="添加银行信息" width="100%">
    <supplier-banks
      :supplier_id="supplier_id"
      v-if="dialogVisibleup"
    ></supplier-banks>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
import supplierbanksVue from "./setsupplierinfobanks.vue";
export default {
  data() {
    return {
      supplier_id: "", //选中的供应商ID
      category_id: "", //成本分类ID 处理后的结果
      tableData: [],
      dialogVisible: false, //控制新增供应商对话框关闭
      dialogVisibleup: false, //控制新增银行对话框关闭
      isaddsupplierbtn: true, //控制新增供应商按钮
      //新增供应商信息
      formInline: {
        supplier_name: "",
        supplier_text: "",
        project_id: "",
        category_id: "",
        category_sunid: "",
      },
    };
  },
  created() {
    //加载供应商数据
    this.category_id = "all"; //all表示加载项目所有的供应商
    this.loadtabledata();
  },
  methods: {
    //显示所有供应商
    displayall() {
      //加载供应商数据
      this.category_id = "all"; //all表示加载项目所有的供应商
      this.loadtabledata();
    },
    //单击添加银行按钮
    clickaddbanksbut(rowid) {
      this.supplier_id = rowid;
      this.dialogVisibleup = true;
      console.log(rowid);
    },
    //更改成本分类选项
    selectChange(itme) {
      //计算生成成本ID（一级分类id/二级分类id/...)
      let temp = "";
      for (let i = itme.length - 1; i >= 0; i--) {
        temp = itme[i] + "/" + temp;
      }
      temp = "/" + temp;
      this.category_id = temp;
    },
    ////单击成本分类控件事件
    chenbenfenliselect(val) {
      if (val.length - 1 >= 0) {
        this.formInline.category_sunid = val[val.length - 1];
        this.isaddsupplierbtn = false; //开启新增供应商按钮
      }
      console.log("成本分类控件子组件传来的数据", val);
      this.selectChange(val);
      // 重新加载供应商列表
      this.loadtabledata();
    },

    //点击确定保存新增供应商按钮
    onSubmitsupplier() {
      (this.formInline.project_id =
        window.sessionStorage.getItem("project_ids")),
        (this.formInline.category_id = this.category_id),
        //保存新增数据到数据库中
        this.addsupplicer(); //新增供应商
    },
    //点击确定保存新增供应商银行按钮
    onSubmitbanks() {},

    //新增供应商
    addsupplicer() {
      axios
        .post("admin/SupplierInfo", this.formInline, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });

          this.dialogVisible = false;
          // 重新加载供应商列表
          this.loadtabledata();
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },

    //加载供应商列表
    loadtabledata() {
      axios
        .get("admin/SupplierInfo", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            category_id: this.category_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data, "******");
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //修改名称axiosAPI
    editname(id,suppliername){
       axios
        .post(
          "admin/SupplierInfo/update/" + id,
          {supplier_name:suppliername},
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("修改成功！", "提示", {
            confirmButtonText: "OK",
          });
           // 重新加载供应商列表
          this.loadtabledata();
        })
    },
    //修改名称方法
    edituser(rowid) {
      ElMessageBox.prompt("请输入修改后的名称", "修改：", {
        confirmButtonText: "保存" ,
        cancelButtonText: "取消",
        inputValue:rowid.supplier_name,
      })
        .then(({ value }) => {
           if(value){
            //调用修改名称axiosAPI
            this.editname(rowid.id,value)
           }
          

        })
        .catch(() => {

        });
    },
    deletesupplier(id) {
      axios
        .post(
          "admin/SupplierInfo/delete/" + id,
          {},
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        //.then((response) => {
        .then(() => {
          ElMessageBox.alert("删除成功！", "提示", {
            confirmButtonText: "OK",
          });

          // 重新加载供应商列表
          this.loadtabledata();
        })
        .catch((error) => {
          ElMessageBox.alert("失败！" + error, "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
  },

  components: {
    "supplier-banks": supplierbanksVue,
  },
};
</script>

<style></style>
