<template>
  <!-- 供应商银行信息表  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <el-button
        class="ml-3"
        type="success"
        size="mini"
        @click="dialogVisible = true"
        >新增银行信息</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="account_name" label="开户名称" width="180" />
      <el-table-column prop="banks" label="开户银行" width="180" />
      <el-table-column prop="account_no" label="银行账号" width="180" />
      <el-table-column prop="contacts" label="联系人" width="80" />
      <el-table-column prop="telephone" label="手机号" width="80" />
      <el-table-column prop="supplier_text" label="备注" width="180" />
      <el-table-column label="操作" width="300" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" plain @click="eidtbanks(scope.row.id)"
              >修改</el-button
            >
            <el-button size="small" plain type="danger" @click="deletebank(scope.row.id)">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <!-- 添加银行信息 -->
  <el-dialog v-model="dialogVisible" title="添加银行信息" width="30%">
    <el-form :inline="true" :model="formbanksinfo" class="demo-form-inline">
      <el-form-item label="账户名称：">
        <el-input v-model="formbanksinfo.account_name"></el-input>
      </el-form-item>
      <el-form-item label="开户银行：">
        <el-input v-model="formbanksinfo.banks"></el-input>
      </el-form-item>
      <el-form-item label="银行账号">
        <el-input v-model="formbanksinfo.account_no"></el-input>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input v-model="formbanksinfo.contacts"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="formbanksinfo.telephone"></el-input>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input
          v-model="formbanksinfo.supplier_text"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitbanks">保存</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 修改银行信息 -->
  <el-dialog v-model="editdialogVisible" title="修改银行信息" width="30%">
    <el-form :inline="true" :model="editformbanksinfo" class="demo-form-inline">
      <el-form-item label="账户名称：">
        <el-input v-model="editformbanksinfo.account_name"></el-input>
      </el-form-item>
      <el-form-item label="开户银行：">
        <el-input v-model="editformbanksinfo.banks"></el-input>
      </el-form-item>
      <el-form-item label="银行账号">
        <el-input v-model="editformbanksinfo.account_no"></el-input>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input v-model="editformbanksinfo.contacts"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="editformbanksinfo.telephone"></el-input>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input
          v-model="editformbanksinfo.supplier_text"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editdialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editonSubmitbanks"
          >保存修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  props: ["supplier_id"], //选中的供应商ID
  data() {
    return {
      tableData: [],
      dialogVisible: false, //控制新增银行对话框关闭
      editdialogVisible: false, //控制修改银行对话框关闭
      banks_id: "", //供应商收款银行ID号
      //新增银行信息
      formbanksinfo: {
        supplier_id: this.supplier_id, //供应商ID
        account_name: "", //账户名
        banks: "", //开户行名
        account_no: "", //账号
        contacts: "", //联系人
        telephone: "", //手机
        supplier_text: "", //备注
      },
      //新增银行信息
      editformbanksinfo: {
        id: "",
        supplier_id: this.supplier_id, //供应商ID
        account_name: "", //账户名
        banks: "", //开户行名
        account_no: "", //账号
        contacts: "", //联系人
        telephone: "", //手机
        supplier_text: "", //备注
      },
    };
  },
  created() {
    //加载供应商银行数据
    this.loadtabledata();
  },
  methods: {
    //新增供应商银行信息
    addsupplicer() {
      axios
        .post("admin/SupplierBanks", this.formbanksinfo, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });

          //清空表单数据
          this.formbanksinfo.supplier_id = ""; //供应商ID
          this.formbanksinfo.account_name = ""; //账户名
          this.formbanksinfo.banks = ""; //开户行名
          this.formbanksinfo.account_no = ""; //账号
          this.formbanksinfo.contacts = ""; //联系人
          this.formbanksinfo.telephone = ""; //手机
          this.formbanksinfo.supplier_text = ""; //备注

          this.dialogVisible = false;
          // 重新加载供应商列表
          this.loadtabledata();
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },

    //加载供应商银行信息列表
    loadtabledata() {
      axios
        .get("admin/SupplierBanks", {
          params: {
            supplier_id: this.supplier_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data, "******");
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //修改供应商银行信息
    editonSubmitbanks() {
      //新增供应商银行信息
      if (!this.editformbanksinfo.supplier_id) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      }
      if (!this.editformbanksinfo.account_name) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      } //账户名
      if (!this.editformbanksinfo.banks) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      } //开户行名
      if (!this.editformbanksinfo.account_no) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      } //账号

      this.editsupplicer();
    },
    //请求远程修改API
    editsupplicer() {
      axios
        .post(
          "admin/SupplierBanks/update/" + this.editformbanksinfo.id,
          this.editformbanksinfo,
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("修改成功！", "提示", {
            confirmButtonText: "OK",
          });

          //清空表单数据
          this.editformbanksinfo.account_name = ""; //账户名
          this.editformbanksinfo.banks = ""; //开户行名
          this.editformbanksinfo.account_no = ""; //账号
          this.editformbanksinfo.contacts = ""; //联系人
          this.editformbanksinfo.telephone = ""; //手机
          this.editformbanksinfo.supplier_text = ""; //备注

          this.editdialogVisible = false;
          // 重新加载供应商列表
          this.loadtabledata();
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    onSubmitbanks() {
      //新增供应商银行信息
      if (!this.formbanksinfo.supplier_id) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      }
      if (!this.formbanksinfo.account_name) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      } //账户名
      if (!this.formbanksinfo.banks) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      } //开户行名
      if (!this.formbanksinfo.account_no) {
        ElMessageBox.alert("失败！不有能空值", "提示", {
          confirmButtonText: "OK",
        });
        return;
      } //账号
      
      this.addsupplicer();
    },
    //获取指定数据，打开修改框
    eidtbanks(id) {
      axios
        .get("admin/SupplierBanks/" + id, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("清空表单数据***", response.data.data[0].id, "******");
          //清空表单数据
          this.editformbanksinfo.id = response.data.data[0].id;
          this.editformbanksinfo.account_name =
            response.data.data[0].account_name; //账户名
          this.editformbanksinfo.banks = response.data.data[0].banks; //开户行名
          this.editformbanksinfo.account_no = response.data.data[0].account_no; //账号
          this.editformbanksinfo.contacts = response.data.data[0].contacts; //联系人
          this.editformbanksinfo.telephone = response.data.data[0].telephone; //手机
          this.editformbanksinfo.supplier_text =
            response.data.data[0].supplier_text; //备注

          this.editdialogVisible = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //删除银行信息
    deletebank(id){
       axios
        .post(
          "admin/SupplierBanks/delete/" + id,
          {},
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("删除成功！", "提示", {
            confirmButtonText: "OK",
          });

          // 重新加载供应商列表
          this.loadtabledata();
        })
        .catch((error) => {
          console.log(error)
          ElMessageBox.alert("失败！"+error, "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    }
  },
};
</script>

<style></style>
