<template>
  <div class="py-2 border-bottom d-fles align-items-center">
    <el-button type="primary" size="mini" @click="creteTopNode"
      >增加顶级分类</el-button
    >
  </div>
  <el-tree
    :data="options"
    :props="defaultProps"
    node-key="id"
    default-expand-all
    :expand-on-click-node="false"
  >
    <template #default="{ node, data }">
      <span class="custom-tree-node">
        <div>
          <span v-if="data.EditStatus">
            <span>名称:</span
            ><el-input v-model="data.name" size="mini"></el-input
            ><span>预算值:</span
            ><el-input
              v-model="data.budget"
              size="mini"
              style="width: 160px"
            ></el-input
          ></span>

          <span v-else
            ><span>{{ node.label }}</span
            ><span style="margin-left: 30px"
              >预算值: {{ data.budget }}
            </span></span
          >
        </div>
        <span>
          <el-button type="primary" plain @click="append(data)" size="small"
            >增加</el-button
          >
          <el-button
            :type="data.EditStatus ? 'default' : 'warning'"
            plain
            @click="editNode(data)"
            size="small"
            >{{ data.EditStatus ? "完成" : "修改" }}</el-button
          >
          <el-button
            type="primary"
            plain
            @click="deletecategory(data)"
            size="small"
            >删除</el-button
          >
        </span>
      </span>
    </template>
  </el-tree>
</template>

<script>
import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  data() {
    return {
      id: 99,
      defaultProps: {
        id: "id",
        label: "name",
        children: "child",
      },
      options: [],
    };
  },
  created() {
    this.__init();
  },
  methods: {
    //创建顶级分类
    creteTopNode() {
      //顶级分类内容
      let subcategory = {
        name: "",
        status: 1, //分类层级
        category_id: 0, //父ID
        order: 11,
        project_id: window.sessionStorage.getItem("project_ids"),
      };
      ElMessageBox.prompt("顶级分类名称", "添加顶级分类", {
        confirmButtonText: "保存",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (value.length > 0) {
            subcategory.name = value;
            //调用新增子分类API  参数subcoment 为子分类的内容
            this.addsubcategoryapi(subcategory);
          } else {
            ElMessageBox.alert("分类名称不能为空！", "提示", {
              confirmButtonText: "OK",
            });
          }
        })
        .catch((value) => {
          ElMessage({
            type: "info",
            message: `加入失败:${value}`,
          });
        });
    },
    //增加子分类
    append(data) {
      //子分类内容
      let subcategory = {
        name: "",
        status: data.status + 1, //分类层级
        category_id: data.id, //父ID
        order: 11,
        project_id: data.project_id,
      };
      ElMessageBox.prompt("子分类名称", "添加子分类", {
        confirmButtonText: "保存",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          subcategory.name = value;
          //调用新增子分类API  参数subcoment 为子分类的内容
          this.addsubcategoryapi(subcategory);
        })
        .catch((value) => {
          ElMessage({
            type: "info",
            message: `添加:${value}子分类失败`,
          });
        });
    },
    //修改子节点
    editNode(data) {
      if (data.EditStatus) {
        this.updatecategoryapi(data.id, data.name, data.budget);
        console.log("完成", data.budget, data.id, data.name);
        data.EditStatus = !data.EditStatus;
      } else {
        data.EditStatus = !data.EditStatus;
      }
    },
    //删除节点
    deletecategory(data) {
      console.log("完成", data.id);
      this.deletecategoryapi(data.id);
    },
    //初始化
    __init() {
      this.axios
        .get("admin/Category", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          let data = response.data.data;
          //递归添加editstatus
          let addEditStatus = function (arr) {
            arr.forEach((item) => {
              item.EditStatus = false;
              //判断数组对象的"child" key是否存在
              if ("child" in item) {
                if (item.child.length) {
                  addEditStatus(item.child);
                }
              }
            });
          };
          addEditStatus(data);
          this.options = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //新增子分类API  参数subcoment 为子分类的内容
    addsubcategoryapi(subcoment) {
      axios
        .post("admin/Category", subcoment, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
          //初始化分类tree
          this.__init();
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //修改名称分类API  参数id->修改分类的ID号 name->为子修改后的分类的内容 ->预算值 内容
    updatecategoryapi(id, value, budgets) {
      axios
        .post(
          "admin/Category/update/" + id,
          { name: value, budget: budgets },
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("修改成功！", "提示", {
            confirmButtonText: "OK",
          });
          //初始化分类tree
          this.__init();
        })
        //.catch((error) => {
        .catch((error) => {
          ElMessageBox.alert(error, "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //删除分类
    deletecategoryapi(id) {
      let project_ids = window.sessionStorage.getItem("project_ids");
      axios
        .post(
          "admin/Category/delete/" + id,
          { project_id: project_ids },
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("删除成功！", "提示", {
            confirmButtonText: "OK",
          });
          //初始化分类tree
          this.__init();
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("有关联数据不能删除！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-tree-node__content {
  height: 40px;
}
</style>
