<template>
<!-- 部门设置  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <el-button type="success" size="mini" @click="dialogVisible = true"
        >增加部门</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="department_name" label="名称" width="180" />
      <el-table-column label="操作" width="300" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" plain @click="edituser(scope.row.id)"
              >修改</el-button
            >
            <el-button size="small" plain type="danger">删除</el-button>
              </el-button-group>
          
        </template>
      </el-table-column>
    </el-table>
  </div>
  <!-- 新增用户 -->
  <el-dialog v-model="dialogVisible" title="新增部门" width="30%">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="部门名称：">
        <el-input v-model="formInline.department_name"></el-input>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model="formInline.sort_order"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </span>
    </template>
  </el-dialog>
  
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      manager_id:"", //选中的用户ID
      tableData: [],
      dialogVisible: false, //控制新增用户对话框关闭
 
      formInline: {
        department_name: "",
        sort_order: "",
        project_id:window.sessionStorage.getItem("project_ids"),
      },
    };
  },
  created() {
    this.loadtabledata();
  },
  methods: {
    //点击确定保存新增部门按钮
    onSubmit() {
      //保存新增数据到数据库中
      this.adduser(); //新增部门
    },
    //新增部门
    adduser() {
      axios
        .post("admin/Department", this.formInline, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });

          this.dialogVisible = false;
          this.loadtabledata(); //重新加载部门列表
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },

    //加载用户列表
    loadtabledata() {
      this.axios
        .get("admin/Department", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.tableData=response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    edituser(rowid) {
      console.log(rowid);
    },
  },
};
</script>


<style>
</style>